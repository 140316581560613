import { useForm } from "react-hook-form";
import Button from "../generic/Button";
import { PhotoUpload } from "../generic/PhotoUpload";
import { usePhotoUpload } from "../../Hooks/usePhotoUploadhook";
import InputField from "../generic/Inputfield";
import { toast } from "react-toastify";
import { Registerstaff } from "../../Utils/Servicess/user";
import Spinner from "../generic/Spinner";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const StepFour = (props) => {
  const [loading, setLoading] = useState();
  const { control, handleSubmit } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    if (data.password != data.confpassword) {
      toast.error("Passwords don't match!");
      return;
    }

    props.setpostdata({ ...props.postdata, password: data.password });

    try {
      setLoading(true);

      let res = await Registerstaff({
        ...props.postdata,
        ...{ password: data.password },
      });
      setLoading(false);
      toast(res ? res.message : " An error occured");

      if (res.success) {
        toast.success("Registered succesfully");

        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  return (
    <div className="bg-white">
      <Spinner loading={loading} />

      <p className="m-3 font-semibold text-primary">Account Setup</p>

      <div className=" flex flex-col ">
        <InputField
          name="password"
          title="Password"
          placeholder="Enter password"
          control={control}
          rules={{
            required: "Password is required",
          }}
          type={"password"}
        />

        <InputField
          name="confpassword"
          title="Confirm Password"
          placeholder="Repeat password"
          control={control}
          rules={{
            required: "Repeat password is required",
          }}
          type={"password"}
        />
        <div className=" flex gap-4 ">
          <Button
            outlined
            text="Previous"
            onBtnClick={() => {
              props.setCurrentStepIndex(2);
              props.previousStep();
            }}
          />
          <Button text="Register" onBtnClick={handleSubmit(onSubmit)} />
        </div>
      </div>
    </div>
  );
};

export { StepFour };
