import axios from "axios";
import { baseURL } from "../URL";

const onGetAllSchool = async ( ) => {
  try {
    const response = await axios.get(`${baseURL}/getallschools`);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};


export { onGetAllSchool };
