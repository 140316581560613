import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onGetAllSchool } from "../../../Utils/Servicess/school";
import Button from "../../../Components/generic/Button";
import { FaEdit } from "react-icons/fa";
import { SchoolDeails } from "./SchoolDetails";
import { ongetstaffcert } from "../../../Utils/Servicess/certificate";
import { CertificateItem } from "../../../Components/ui/CertificateItem";
import Spinner from "../../../Components/generic/Spinner";
import UpdateCert from "../../../Components/ui/UpdateCert";
import { ConfirmDelete } from "../../../Components/ui/DeleteConfirm";
import { GrCertificate } from "react-icons/gr";
import { RiArchiveDrawerFill } from "react-icons/ri";
import { BiSolidSchool } from "react-icons/bi";
import ViewCert from "../../../Components/ui/ViewCert";
import UpdateID from "../../../Components/dashboard/UpdateID";
import HeaderItem from "../../../Components/dashboard/Header";
import {
  onGetAllstaffs,
  onGetpastWork,
  onGetSchoolClasses,
  onGetstaffuser,
} from "../../../Utils/Servicess/user";
 import { VerificationBadge } from "../../../Components/dashboard/VerificationBadge";
import { FaCertificate } from "react-icons/fa6";
import EmptyItem from "../../../Components/dashboard/EmptyItem";
import { PiChalkboardTeacherThin } from "react-icons/pi";
import ChangeSchoolPre from "../../../Components/dashboard/ChangeSchoolPre";
import WorkItem from "../../../Components/dashboard/WorkItem";
import { dummy_image } from "../../../constants/imports";
import ClassItem from "./ClassItem";

export default () => {
  var authuser = useSelector((state) => state.selectedStaff);
  var [school, setschool] = useState({});
  var [certificates, setcertificates] = useState([]);
  const [showpre, setpre] = useState(false);
  const dispatcher = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showidupdate, setshowidupdate] = useState(false);
   const [selectedcert, setselectedcert] = useState();
  var [showaddmodal, setshowaddmodal] = useState(false);
  var [showchangeschool, setshowchangeschool] = useState(false);
  var [showview, setshowview] = useState(false);
  var [showdeletemodal, setshowdeletemodal] = useState(false);
  const [staffacheivements, setstaffacheivements] = useState([]);
  const [staffpastemployment, setstaffpastemployment] = useState([]);
  const [allstaffs, setallstaffs] = useState([]);

  const [schoolClasses, setschoolClasses] = useState([]);

  const getSchoolClasses = async () => {
    let postData = {
      schoolname: school.schoolname,
    };

    setLoading(true);
    var response = await onGetSchoolClasses(postData);
    setLoading(false);
    var emptyArr = [];
    Object.keys(response).map((key) => {
      console.log(
        response[key].assignedTeacher,
        `${authuser.firstname} ${authuser.lastname}`,
        response[key].assignedTeacher
          ?.toLowerCase()
          .includes(`${authuser.firstname} ${authuser.lastname}`),
        "hjh"
      );
      if (
        response[key].assignedTeacher
          ?.toLowerCase()
          .includes((`${authuser.firstname} ${authuser.lastname}`).toLowerCase())
      ) {
        emptyArr.push(response[key]);
      }
    });

    setschoolClasses(emptyArr);
  };

  const getallstaffs = async () => {
    setLoading(true);
    var response = await onGetAllstaffs();
    setLoading(false);

    var emptyArr = [];
    Object.keys(response).map((key) => {
      if (response[key].schhool == school.schoolname)
        emptyArr.push(response[key]);
    });
    setallstaffs(emptyArr);
    console.log(emptyArr);
  };

  const getstaffuser = async () => {
    setLoading(true);
    var postdata = {
      email: authuser.email,
    };
    var res = await onGetstaffuser(postdata);
    dispatcher({ type: "set-user", payload: res });
    setLoading(false);
  };

  const getcertificates = async () => {
    var postdata = {
      email: authuser.email,
    };

    var certsobj = [];

    var certs = await ongetstaffcert(postdata);

    for (let item in certs.certicates) {
      console.log(item);
      var itemobj = {
        title: item,
        url: certs.certicates[item],
      };

      certsobj.push(itemobj);
    }

    setcertificates(certsobj);

    console.log(certsobj);
  };
  const getstaffemployhistory = async () => {
    var postdata = {
      email: authuser.email,
    };

    var recordobj = [];

    var response = await onGetpastWork(postdata);

    for (let item in response.record) {
      console.log(response.record[item], "test...");

      recordobj.push({ ...response.record[item], title: item });
    }

    setstaffpastemployment(recordobj);

    console.log(recordobj, "test...");
  };
  const getallschools = async () => {
    setLoading(true);
    var allschools = await onGetAllSchool();
    setLoading(false);

    setschool(allschools[authuser.schhool]);
    console.log(allschools, "...kkk...", authuser);
    dispatcher({ type: "set-school", payload: allschools[authuser.schhool] });
    console.log(allschools[authuser.schhool]);
  };

  useEffect(() => {
    const init = async () => {
      await getcertificates();

      await getstaffemployhistory();
      await getSchoolClasses();
      await getallstaffs();
    };
    init();
  }, [school]);

  useEffect(() => {
    getallschools();
  }, []);

  const onUploadID = () => {};
  return (
    <div className=" flex flex-col justify-center items-center pb-4 px-4">
      <Spinner loading={loading} />
      {showaddmodal && (
        <UpdateCert
          refresh={getcertificates}
          selectedcert={selectedcert}
          showaddmodal={setshowaddmodal}
        />
      )}

    
      {showpre && (
        <ChangeSchoolPre setpre={setpre} proceed={setshowchangeschool} />
      )}
      {showidupdate && (
        <UpdateID
          refresh={getstaffuser}
          selectedcert={selectedcert}
          showaddmodal={setshowidupdate}
        />
      )}
      {showdeletemodal && (
        <ConfirmDelete
          loading={setLoading}
          refresh={getcertificates}
          selectedcert={selectedcert}
          showaddmodal={setshowdeletemodal}
        />
      )}
      {showview && (
        <ViewCert
          selectedcert={selectedcert}
          certificates={certificates}
          showaddmodal={setshowview}
        />
      )}

      <div className=" w-full md:grid md:grid-cols-3 justify-center items-center mt-4 px-6">
        {/* ID */}
        <div className=" flex flex-col bg-white rounded justify-center items-center  relative min-h-[420px] p-6  m-2">
          <p className="text-primary font-semibold absolute top-5 m-2 mb-4 self-start">
            ID document
          </p>

          <img
            className="  border border-primary  inline"
            height="100px"
            width="100px"
            src={`${authuser.iddoc ? authuser.iddoc : dummy_image}}`}
          />

          {/* <div className="absolute bottom-0">
            <Button
              text="Change ID"
              onBtnClick={() => {
                setshowidupdate(true);
              }}
            />
          </div> */}
        </div>

        {/* Profile */}
        <div className=" flex flex-col bg-white border border-primary rounded justify-center items-center p-5 relative min-h-[430px] m-2">
          <p className="text-primary font-semibold absolute top-5 m-2 mb-4 self-start">
            Profile details
          </p>

          <img
            className="rounded-full border border-primary  inline"
            height="100px"
            width="100px"
            src={`${authuser.passport}`}
          />

          <div className="w-full font-light">
            <p>
              <span className="font-normal text-sm">Firstname:</span>
              {authuser.firstname}
            </p>

            <p>
              <span className="font-normal text-sm">Lastname:</span>
              {authuser.lastname}
            </p>
            <p>
              <span className="font-normal text-sm">Email:</span>
              {authuser.email}
            </p>
            <p>
              <span className="font-normal text-sm  ">Phone:</span>
              {authuser.phone}
            </p>
            <p>
              <span className="font-normal text-sm">Role:</span>
              {authuser.role}
            </p>
            {authuser.role == "Teacher" && (
              <>
                {" "}
                <p>
                  <span className="font-normal text-sm ">
                    Teaching Category:
                  </span>
                  {authuser.teachingcat}
                </p>
                <p>
                  <span className="font-normal text-sm">Teaching Subject:</span>
                  {authuser.subject}
                </p>
              </>
            )}
          </div>
 
        </div>

        {/* School */}
        <div className=" flex flex-col bg-white rounded justify-center items-center min-h-[420px] relative p-6  m-2">
          <p className="text-primary font-semibold absolute top-5 m-2 mb-4 self-start">
            School details
          </p>
          <img
            className="rounded-full border border-primary  inline"
            height="100px"
            width="100px"
            src={`${school?.schoollogo}`}
          />

          <div className="w-full font-light">
            <p>
              <span className="font-normal">School name:</span>
              {school?.schoolname}
            </p>

            <p>
              <span className="font-normal">Founded:</span>
              {school?.yearfounded}
            </p>
            <p>
              <span className="font-normal">Address:</span>
              {school?.address}
            </p>
            <p className="truncate">
              <span className="font-normal">{school?.creatorrole}:</span>{" "}
              {school?.user}
            </p>
          </div>
          {/* <div className="absolute flex justify-center items-center bottom-0">
            <Button
              outlined
              text={
                <>
                  Change school{" "}
                  <FaEdit size={20} className="text-primary  inline self-end" />
                </>
              }
              onBtnClick={() => {
                setpre(true);
              }}
            />
          </div> */}
        </div>
      </div>
      <hr />

      <div>
        <HeaderItem icon={<FaCertificate />} title="Staff Certificates" />

        <div className="grid grid-cols-2 gap-6">
          {certificates.map((i, key) => {
            return (
              <CertificateItem
                setshowdel={setshowdeletemodal}
                setshowview={setshowview}
                setshowaddmodal={setshowaddmodal}
                key_={key}
                setselectedcert={setselectedcert}
                item={i}
              />
            );
          })}
        </div>

        <br />
        <div className="bg-white p-4">
          <HeaderItem
            icon={<PiChalkboardTeacherThin />}
            title="Assigned classes enumeration"
          />
          {schoolClasses.map((item, key) => (
            <>
              <ClassItem
                key_={key}
                refresh={getSchoolClasses}
                staffs={allstaffs}
                setLoading={setLoading}
                item={item}
              />
            </>
          ))}
        </div>
        <br />
        <div className="p-4 bg-white">
          <HeaderItem
            icon={<GrCertificate />}
            title="Staff's Awards &Achievements"
          />

          {!staffacheivements.length == 0 ? (
            <div>
              {staffacheivements.map(() => (
                <></>
              ))}
            </div>
          ) : (
            <EmptyItem />
          )}
        </div>

        <HeaderItem icon={<BiSolidSchool />} title=" Current School Details" />

        <SchoolDeails />

        <br />
        <div className="p-4 bg-white">
          <HeaderItem
            icon={<RiArchiveDrawerFill />}
            title="Records of past employment"
          />

          {!staffpastemployment.length == 0 ? (
            <div>
              {staffpastemployment.map((i) => (
                <WorkItem item={i} />
              ))}
            </div>
          ) : (
            <EmptyItem />
          )}
        </div>
      </div>
    </div>
  );
};