import { useDispatch, useSelector } from "react-redux";
import Button from "../generic/Button";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { useEffect } from "react";
import Staffprofile from "../../Pages/Userprotected/stafffs/Staffprofile";

export default (prp) => {
  var props = useSelector((state) => state.selectedStaff);
  var userschool = useSelector((state) => state.selectedSchool);
  var categories = props.schoolcategories?.split(",");
  const navigate=useNavigate()

  const dispatcher =useDispatch()
useEffect(()=>{

  dispatcher({ type: "set-pagetitle", payload: "Staff dashboard" });

},[])


useEffect(()=>{
  if(props.email==undefined){
    navigate('/dashboard/staffs')
   return
   }
},[])
  return (<div>
        <FaArrowLeft
                className=" ml-3 m-3 mb-3 inline  text-primary"
                size={20}
                onClick={() => navigate(-1)}
              />
    <div
      style={{
        backgroundImage: `url(${
          userschool.coverphoto == ""
            ? "https://firebasestorage.googleapis./v0/b/sclverse.appspot.com/o/predefs%2Fcoverphoto.jpg?alt=media&token=447e7c08-19fd-4d9f-ac87-679bd0039793"
            : userschool.coverphoto
          })`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
      className=" p-4 rounded coverphotoholder md:flex  flex justify-between  bg-[#0808088f]  "
  > 

    <div className="flex flex-col h-full">


    
      <img className="rounded-full str stroke-white max-h-[200px] w-[200px]"  src={props.passport} />
      <h1 className="font-bold text-white     text-lg ">
        {props.firstname}  
        {props.lastname}
      </h1>
      <p className="text-white  text-sm font-semibold">{props.email}</p>
      <p className="text-white  text-sm font-semibold">{props.phone}</p>
      <p className="text-white  text-sm font-semibold">{props.verified?"Verified":"Not verified"}</p>

  </div>
      <div className=" md:flex  justify-end items-center ">
        <div className="flex justify-start flex-col p-1 bg-[#ffffff44] m-3 rounded items-start">
          <p className="text-white font-light"><span className="font-bold">Staff at:</span><br/> {props.schhool}</p>
          <p className="text-white font-light "><span className="font-bold">Role:</span> <br/> {props.role}</p>
          <p className="text-white  text-xl font-semibold">{props.subject&&props.subject}</p>
        </div>
 
     
      </div>
     
  
     
    </div>
    <Button
            width={40}
            outlined
            text="Send message"
            onBtnClick={() => {
              navigate("/dashboard/staffmessage")
            }}
          />

          <Staffprofile/>
    </div>);
};
 