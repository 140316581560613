import { useState } from "react";
import { FaStarHalfAlt } from "react-icons/fa";
import { FaPlay } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ReviewQuestModal } from "./ReviewsQuestModal";

const ReviewItem = (props) => {
  const [showdetail, setshowdetail] = useState(false);
  const dispatcher = useDispatch();
  const navigate = useNavigate();

  const reviewIsViewonly = useSelector((state) => state.reviewIsViewonly);

  return (<>
        {showdetail && <ReviewQuestModal show={setshowdetail} questions={props.item.questions} />}
  

    <div
      onClick={() => {
        if (reviewIsViewonly) {
setshowdetail(true)
         } else {
          dispatcher({ type: "setSelected-review-item", payload: props.item });
          dispatcher({
            type: "setSelected-review-itemKey",
            payload: props.key_,
          });
          navigate("/reviewentry");
        }
      }}
      className="p-4 m-2 bg-customGray flex justify-between"
    >


      <p className="w-[60%] truncate text-[16px] font-bold text-primary ">
        {" "}
        {props.item.ItemName}
      </p>

      <p>
        {props.item.score}/{props.item.totalScore}
      </p>
      <p>{props.item.status == "nill" ? "Not initiated" : props.item.status}</p>
      <p>{props.item.updatedAt}</p>
    </div>  </>
  );
};

export { ReviewItem };
