import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import InputField from "../../../Components/generic/Inputfield";
import Button from "../../../Components/generic/Button";
import { useForm } from "react-hook-form";
import { textareaRegx, textReg } from "../../../Utils/regex";
import Spinner from "../../../Components/generic/Spinner";
import { FaArrowLeft, FaS } from "react-icons/fa6";
import { toast } from "react-toastify";
import { onSendMessage } from "../../../services/message";
import React from "react";
import { removeNonLetters, removeSpecialChars } from "../../../Utils/govfunctions";

export default (props) => {
  const userschool = useSelector((state) => state.selectedSchool);
  const navigate = useNavigate();
  var selectedStaff = useSelector((state) => state.selectedStaff);

  const [loading, setLoading] = useState(false);
  const { handleSubmit, control } = useForm();
  const dispatcher = useDispatch();
  var authuser = useSelector((state) => state.userStore);
  const toastId = React.useRef(null);

  useEffect(() => {
    dispatcher({ type: "set-pagetitle", payload: "compose message" });

    if (selectedStaff.schhool == undefined) {
      navigate("/dashboard/staffs");
      return;
    } }, []);

  const SendMessage = async (data) => {
    var postData = {
      to: removeSpecialChars( selectedStaff.email),
      from: authuser.email,
      user: `${authuser.firstname} ${authuser.lastname}`,
      message: {
        files: [],
        senderType: `${
          authuser.inspectortype ? authuser.inspectortype : authuser.position
        }`,
        to:  removeSpecialChars( selectedStaff.email),
        from: authuser.email,
        title: data.title,
        user: `${authuser.firstname} ${authuser.lastname}`,

        time: new Date().toDateString(),
        message: data.message,
      },
    };

    try {
      setLoading(true);

      let res = await onSendMessage(postData);
      toast.success(res.message);
      navigate("/dashboard/messages");
    } catch (e) {
      console.log(e);
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };
  const SendBulkMessage = async (data) => {
    await Promise.all(
      props.staffs.map(async (staff) => {
        try {
          setLoading(true);

          var postData = {
            to: removeSpecialChars(staff.email),
            from: authuser.email,
            user: `${authuser.firstname} ${authuser.lastname}`,
            message: {
              files: [],
              senderType: `${
                authuser.inspectortype
                  ? authuser.inspectortype
                  : authuser.position
              }`,
              to:  removeSpecialChars(staff.email),
              from: authuser.email,
              title: data.title,
              user: `${authuser.firstname} ${authuser.lastname}`,

              time: new Date().toDateString(),
              message: data.message,
            },
          };

          let res = await onSendMessage(postData);
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast(res.message);
            toast.success();
            navigate("/dashboard/messages");
          }
        } catch (e) {
          console.log(e);
          toast.error(e);
        } finally {
          setLoading(false);
        }
      })
    );
  };

  return (
    <div className="px-10 flex justify-center items-center">
      <Spinner loading={loading} />

      <div className="bg-white rounded w-[80vw] md:w-[50vw] p-10 ">
        <div className="bg-white text-xs p-2">
          {!props.isbulk && (
            <p>
              {" "}
              <FaArrowLeft
                className=" ml-3 m-3 mb-3 inline  text-primary"
                size={20}
                onClick={() => navigate(-1)}
              />
              Send message to{" "}
              <span className="bg-white border text-xs font-semibold text-primary border-gray-400 rounded p-2">
                {selectedStaff.firstname} {selectedStaff.lastname}
              </span>
            </p>
          )}
        </div>{" "}
        <InputField
          name="title"
          title="Title of message"
          rules={{
            required: "Title is required",
            pattern: {
              value: textareaRegx,
              message: "Invalid title",
            },
          }}
          placeholder="Enter a title"
          control={control}
        />
        <InputField
          name="message"
          title="Write message"
          isTextArea
          placeholder={"Write a message"}
          control={control}
          rules={{
            required: "message is required",
            pattern: {
              value: textareaRegx,
              message: "Invalid message",
            },
          }}
        />
        <div className="w-full flex  gap-3 justify-end">
          <Button
            width={55}
            text="Send"
            onBtnClick={
              props.isbulk
                ? handleSubmit(SendBulkMessage)
                : handleSubmit(SendMessage)
            }
          />
        </div>
      </div>
    </div>
  );
};
