import { useEffect, useState } from "react";
import { paymentItems } from "../../../constants/data"
import { onGetPaymentinfo } from "../../../services/payment";
import { PaymentListItem } from "./PaymentListItem"
import { toast } from "react-toastify";
import Spinner from "../../generic/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { FaArrowLeft, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default ()=>{
    const [schoolpaments, setschoolpaments] = useState(null);
    const [loading, setLoading] = useState(false);
    const school_=useSelector((state)=>state.selectedSchool)

    const userschool=useSelector((state)=>state.selectedSchool)
    const navigate=useNavigate()
    const onGetPayments = async () => {
        const postData = {
          schoolname: userschool.schoolname,
        };
    
        setLoading(true);
    
        try {
          var res = await onGetPaymentinfo(postData);
    
          setschoolpaments(res.payment);
    
          setLoading(false);
        } catch (e) {
          setLoading(false);
    
          console.log(e);
          toast.error(e);
        }
      };


      useEffect(()=>{

        onGetPayments()
      },[])
    return(   <div className=" bg-customGray  flex justify-center flex-col items-center   text-sm rounded-b-md p-3 m-2">


<div className="flex justify-center items-center">
<FaArrowLeft className="m-3 mb-3 text-primary inline " size={30} onClick={()=>navigate(-1)}/><h1 className="inline">{school_.schoolname} Payments</h1></div>
<Spinner loading={loading} />

        <div className="bg-gray-100 p-2">
          {paymentItems.map((i) => (
            <PaymentListItem isdash={true} payment={schoolpaments} item={i} />
          ))}
        </div>
      </div>)
}