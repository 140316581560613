export default function  HeaderItem (props){


return(<div className=" mt-2 p-4 bg-white "> 


<p className="text-[#171717b5] font-semibold   gap-3 uppercase  m-2 flex justify-center items-center  self-start">{props.icon}
{props.title}</p>

</div>)

}