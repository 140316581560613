import axios from "axios";
import { baseURL } from "../Utils/URL";

const onGetAllFees = async (postData) => {
    try {
        const response = await axios.post(`${baseURL}/getfees`, postData);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};


const onEditFee =async(postData)=>{

    try {
        const response = await axios.post(`${baseURL}/editfee`, postData);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
    
}
const onDeleteFee =async(postData)=>{

    try {
        const response = await axios.post(`${baseURL}/deletefee`, postData);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
}


const onAddFee =async(postData)=>{
   
    try {
        const response = await axios.post(`${baseURL}/addfee`, postData);  
        return response.data; 
    } catch (error) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
    
}


export{onGetAllFees,onDeleteFee, onAddFee ,onEditFee}

