import React from "react";

 
const Button = ({
  outlined,
  text,
  onBtnClick,
  width,
  isdisabled,
  Icon,
  OverideStyle
}) => {
  return (
    <button
    disabled={isdisabled}

      onClick={onBtnClick}
      type="button"
      className={`inline ${
        outlined ? " bg-faint text-primary" : " bg-primary text-white"
      } ${isdisabled?"bg-gray-400 ":""} ${
        width ? "w-[" + width + "px]" : "w-full"
      } transition cursor-pointer duration-200  focus:ring-opacity-50 py-3 px-4 rounded border my-5 text-xs shadow-sm hover:shadow-md font-semibold border-primary text-center inline-block ${OverideStyle} border-black`}
    >
      <div className="flex items-center justify-center">
        {Icon && (
          <Icon
            size={20}
            className={`${outlined ? "text-primary  " : "text-white "} mr-2 ${OverideStyle}`}
          />
        )}{" "}
        <span className={`inline-block ${OverideStyle}`} text-center >{text}</span>
      </div>
    </button>
  );
};

export default Button;
