import { useForm } from "react-hook-form";
import Button from "../generic/Button";
import InputField from "../generic/Inputfield";
import { Schoolpicker } from "./Schoolpicker";
import { useSchoolPickerhook } from "../../Hooks/useSchoolpickerhook";
import { Rolepicker } from "./RolePicker";
 import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { onGetAllSchool } from "../../Utils/Servicess/school";
import DatePicker from "react-datepicker";
import { MdDateRange } from "react-icons/md";
import { ListPicker } from "../ui/ListPicker";
import { usePickerhook } from "../../Hooks/usePickerhook";

const StepThree = (props) => {




   const positionhook = usePickerhook();

   const [DOB, setDOB] = useState(new Date());
  const [DOE, setDOE] = useState(new Date());


  const [allschools, setallschools]=useState([])

  const { control, handleSubmit } = useForm();
  const positions = [
    "Permanent Secretary (1)",
    "Director of QA (1)",
    "Deputy Directors of QA (2)",
    "Assistant Director (1)",
    "Chief Education Officer (1)",
    "Assistant Chief Education Officer (2)",
    "Principal Education Officer (2)",
    "Senior Education Officer (1)"
];

  const init=async ()=>{
var response= await onGetAllSchool()



 

setallschools(Object.keys(response))


  }

  useEffect(()=>{

init()

  },[])
  const onSubmit = (data) => {
    if (!positionhook.pickedRole) {
      {
        toast.error("Select a valid position ");
        return;
      }
    }
    
    props.nextStep();
    props.setCurrentStepIndex(3);

    var postdata ={
         position:positionhook.pickedRole,
        doe:DOE.toString(),
        dob:DOB.toString()
    }
    
    props.setpostdata({...props.postdata, ...postdata})

  };

  return (
    <div className="bg-white">
      <p className="m-3 text-primary font-semibold">Gov. Staff details</p>

          
        {/* Date of birth */}
        <div className="my-4">
          <p className="text-xs mb-2 ">Date Of Birth</p>
          <div className=" px-4 py-1  flex text-sm bg-gray-50 w-full  rounded   items-center">
            <MdDateRange className="p-2 " size={30} />

            <DatePicker className="font-light" selected={DOB} onChange={(date) => setDOB(date)} />
          </div>
        </div>
        {/* Date of entry to service */}
        <div className="my-4">
          <p className="text-xs mb-2 ">Date of entry to service</p>
          <div className=" px-4 py-1  flex text-sm bg-gray-50 w-full  rounded   items-center">
            <MdDateRange className="p-2 " size={30} />

            <DatePicker className="font-light" selected={DOE} onChange={(date) => setDOE(date)} />
          </div>
        </div>

        <ListPicker
          title="Position"
          hook={positionhook}
          list={positions}
        />


      <div className=" flex gap-4 ">
        <Button
          outlined
          text="Previous"
          onBtnClick={() => {
            props.setCurrentStepIndex(1);
            props.previousStep();
          }}
        />
        <Button text="Next" onBtnClick={handleSubmit(onSubmit)} />
      </div>
    </div>
  );
};

export { StepThree };
