import axios from "axios";
import { baseURL } from "../URL";

const onconfirmuserExist = async (postData) => {
    try {
      const response = await axios.post(`${baseURL}/confirmgovuser`, postData);
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message === undefined) {
        throw error.message;
      } else { 
        throw error?.response?.data?.message;
      }
    }
  };

  const onGetpastWork = async (postData) => {
    try {
      const response = await axios.post(`${baseURL}/getpastwork`, postData);
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message === undefined) {
        throw error.message;
      } else {
        throw error?.response?.data?.message;
      }
    }
  };
  

  
const onGetAllstaffs= async (postData) => {
  try {
      const response = await axios.get(`${baseURL}/getstaffs`);
      return response.data;
  } catch (error) {
      if (error?.response?.data?.message === undefined) {
          throw error.message
      } else {
          throw error?.response?.data?.message;
      }
  }
};
const onGetSchoolClasses = async (postData) => {
  try {
    const response = await axios.post(`${baseURL}/getschoolclass`, postData);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};



  const onGetAllcertificates = async (postData) => {
    try {
        const response = await axios.post(`${baseURL}/getcerts`, postData);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};

const onGetstaffuser = async (postData) => {
  try {
    const response = await axios.post(`${baseURL}/getstaffuser`, postData);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
  
const Registerstaff = async (postData) => {
    try {
      const response = await axios.post(`${baseURL}/registergovstaff`, postData);
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message === undefined) {
        throw error.message;
      } else {
        throw error?.response?.data?.message;
      }
    }
  };


  const onUploaddocument = async (formdata) => {
    try {
      const response = await axios.post(`${baseURL}/uploaddocument`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message === undefined) {
        throw error.message;
      } else {
        throw error?.response?.data?.message;
      }
    }
  };

  const onSendEmail = async (postData) => {
    try {
        const response = await axios.post(`${baseURL}/sendemailverification`, postData);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
}


const onGetGovconfig= async (postData) => {
  try {
      const response = await axios.get(`${baseURL}/getgovconfig`);
      return response.data;
  } catch (error) {
      if (error?.response?.data?.message === undefined) {
          throw error.message
      } else {
          throw error?.response?.data?.message;
      }
  }
};


  export{onconfirmuserExist,onGetGovconfig ,onGetpastWork,onGetAllcertificates ,onGetstaffuser,onGetSchoolClasses,onGetAllstaffs,onSendEmail, Registerstaff,onUploaddocument}