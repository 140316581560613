import { BiListMinus } from "react-icons/bi";
import { CiLocationOn } from "react-icons/ci";
import { MdDateRange } from "react-icons/md";
import { MdOutlineWorkOutline } from "react-icons/md";


export default (props)=>(<div className=" shadow rounded m-2 p-2 flex">

    <BiListMinus/>
<div>
    <p className="font-semibold ">{props.item.title}</p>
<p className="text-sm font-medium">{<MdOutlineWorkOutline className=" inline m-1"/>}{props.item.role}</p>
<p className="text-xs font-light">{<CiLocationOn className=" inline m-1"/>}{props.item.address}</p>
<p className="text-xs font-light">{<MdDateRange className=" inline m-1"/>}{props.item.date}</p>
</div>
   

</div>)