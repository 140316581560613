
import axios from "axios";
import { baseURL } from "../URL";

const ongetstaffcert = async ( postdata) => {
    try {
      const response = await axios.post(`${baseURL}/setstaffcert`,postdata);
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message === undefined) {
        throw error.message;
      } else {
        throw error?.response?.data?.message;
      }
    }
  };
  const onDeleteCert =async (postdata)=>{

    try {
      const response = await axios.post(`${baseURL}/deletestaffcert`,postdata);
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message === undefined) {
        throw error.message;
      } else {
        throw error?.response?.data?.message;
      }
    }
  };
  const onUpdateCert= async (formdata)=>{
 
    try {
        const response = await axios.post(`${baseURL}/updatestaffcert`, formdata, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }});
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }

  }
  const onUpdateID= async (formdata)=>{
 
    try {
        const response = await axios.post(`${baseURL}/updatestaffid`, formdata, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }});
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }

  }

  export{ongetstaffcert, onUpdateCert, onUpdateID, onDeleteCert}