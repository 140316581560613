import { FaCheck, FaCheckCircle } from "react-icons/fa";
import { logo, logolight } from "../../constants/imports";
import Button from "../../Components/generic/Button";
import { useNavigate, useParams } from "react-router-dom";
import { onChangeuserPass, onVerifyuser } from "../../Utils/Servicess/auth";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import InputField from "../../Components/generic/Inputfield";
import { useForm } from "react-hook-form";
import Spinner from "../../Components/generic/Spinner";

const ResetPass = () => {
  var navigate = useNavigate();
  const [message, setmessge] = useState();
  const { control, handleSubmit } = useForm();
  var { userId } = useParams();
  const [loading, setLoading] = useState(false);

  const reset = async (data) => {
    if (data.confpassword != data.password) {
      toast.error("Passwords dont match");
      return;
    }
    var postdata = {
      email: userId,
      newpassword: data.password,
    };
    setLoading(true);
try{

  var res = await onChangeuserPass(postdata);
    toast.success(res.message);
    setmessge(res.message);
    if (res.success) {
      navigate("/");
    }
}

catch(e){
toast.error(e)
console.log(e)

}
  
    setLoading(false)

  };

  useEffect(() => {}, []);
  return (
    <div className="bg-primary flex justify-center items-center h-[100vh] w-full ">
      <Spinner loading={loading} />
      <div className="bg-white rounded-lg  w-[80vw]     flex justify-center flex-col  items-center">
        <img
          className="flex justify-center mb-4 w-[130px]  items-center"
          src={logo}
        />
        <p>{userId}</p>
        <h1 className="text-lg font-light mb-4">{message}</h1>

        <InputField
          name="password"
          title="Password"
          placeholder="Enter password"
          control={control}
          rules={{
            required: "Password is required",
          }}
          type={"password"}
        />

        <InputField
          name="confpassword"
          title="Confirm Password"
          placeholder="Repeat password"
          control={control}
          rules={{
            required: "Repeat password is required",
          }}
          type={"password"}
        />

        <Button width={"auto"} text="Reset" onBtnClick={handleSubmit(reset)} />
      </div>
    </div>
  );
};

export { ResetPass };
