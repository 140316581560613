import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default (props) => {
  var dispatcher = useDispatch();
  var navigate = useNavigate();

  return (
    <div
      onClick={() => {
        dispatcher({ type: "set-selectedStaff", payload: props.item });
        navigate("/dashboard/staff");
      }}
      className="bg-white rounded shadow  border   hover:border-primary pb-2 "
    >
      <img
        className="w-full border border-primary h-[200px] bg-contain"
        alt="School logo empty"
        src={props.item.passport}
      />
      <p className="font-semibold  text-primary ml-3">
        {props.item.firstname} {props.item.lastname}{" "}
      </p>
      <p className="font-light text-sm ml-3 text-primary ">{props.item.role}</p>
      <p className="font-light text-sm ml-3 text-gray-500 ">
        {props.item.schhool}
      </p>
    </div>
  );
};
