import { MdVerified } from "react-icons/md";
import { FiUserX } from "react-icons/fi";


const VerificationBadge=(props)=>{




    return(<div className={` border flex border-customGray justify-center items-center p-4 rounded bg-white`}>
<p className={`font-bold text-center flex justify-center items-center text-xs ${props.verified?"text-black ":"text-rose-500"}`}   >{ props.verified ?<MdVerified size={20} className="inline m-1 text-green-500 "/>:<FiUserX   size={20} className="inline m-1 text-rose-500 "/>}{props.verified?"Verified":"Not Verified"}</p>
    </div>)
}

export{VerificationBadge}