import { useLocation, useNavigate } from "react-router-dom";
import { sidebaritems } from "../../constants/data";
import { logolight } from "../../constants/imports";
import { useDispatch } from "react-redux";

const SideBar = () => {
  var location = useLocation();
  const navigate = useNavigate();

  var dispatcher = useDispatch();

  return (
    <div className="bg-customBlack  w-[18vw] h-full fixed">
      <div className="p-4 mt-8">
        {sidebaritems.map((item) => (
          <div
            onClick={() => {
              navigate(`${item.path}`);
              dispatcher({ type: "set-page-title", payload: item.name });
            }}
            className={`p-2 ${
              location.pathname === item.path && "bg-primary "
            } ${
              location.pathname == "accountdashboard" && "bg-primary "
            } my-4 rounded `}
          >
            <p className="inline text-[13px] cursor-pointer  font-semibold text-white content-center">
              {item.icon} {item.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export { SideBar };
