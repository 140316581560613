import { useDispatch, useSelector } from "react-redux";
import Button from "../../generic/Button";
import AddFacilitymodal from "../../facilities/Addfacilitymodal";
import { useEffect, useState } from "react";
import Spinner from "../../generic/Spinner";
import { onGetAllfacility } from "../../../services/facility";
import { FacilityItem } from "../../facilities/FacilityItem";
import { FaArrowLeft, FaTrash } from "react-icons/fa";
import { PiEmptyThin } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { IoReload } from "react-icons/io5";

import { ConfirmDelete } from "../../facilities/DeleteConfirm";

const AdminFacilities = () => {
  const dispatcher = useDispatch();
  const [loading, setLoading] = useState(false);
  const facilities = useSelector((state) => state.facilities);
  const [Checked, setchecked] = useState([]);
  const [showdeletefacmodal, setshowdeletefacmodal] = useState(false);
  const [refresh, setrefresh] = useState(0);
  const school_ = useSelector((state) => state.selectedSchool);
  const navigate = useNavigate();
  

  useEffect(() => {
    console.log(school_.schoolname);

    if (school_.schoolname == undefined) {
      navigate("/dashboard/allschools");
      return;
    }
  }, []);

  const NoFacility = () => (
    <div className="flex  w-full flex-col justify-center items-center  p-4">
      <PiEmptyThin className="text-primary" size={30} />
      <p className="text-primary font-thin text-2xl ">No Facility added yet</p>
    </div>
  );

  dispatcher({ type: "set-page-title", payload: "Facilities" });
  var [showaddmodal, setshowaddmodal] = useState(false);

  useEffect(() => {
    console.log(Checked);
  }, [Checked]);

  const init = async () => {
    setLoading(true);

    const postdata = { school: school_.schoolname };
    const res = await onGetAllfacility(postdata);
    console.log(postdata);
    console.log(res);
    setLoading(false);
     if (res.school) {
      let p = [];
      for (let facility in res.school) {
        p.push(res.school[facility]);
      }
       dispatcher({ type: "set-facilities", payload: p });
    }
  };

  useEffect(() => {
    init();
  }, [showaddmodal, refresh]);

  return (
    <>
      <Spinner loading={loading} />
      {showaddmodal && (
        <AddFacilitymodal
          school={school_.schoolname}
          showaddmodal={setshowaddmodal}
        />
      )}
      {showdeletefacmodal && (
        <ConfirmDelete
          school={school_.schoolname}
          titlelist={Checked}
          loading={setLoading}
          showaddmodal={setshowdeletefacmodal}
        />
      )}

      <div className="p-10 bg-customGray min-h-[100vh]">
        <div className="flex   items-center">
          <FaArrowLeft
            className="m-3 mb-3 text-primary"
            size={30}
            onClick={() => navigate(-1)}
          />
          <h1>{school_.schoolname} Facilities</h1>{" "}
          <IoReload
            onClick={() => {
              setrefresh(refresh + 1);
            }}
            className="m-3 mb-3 text-primary"
            size={30}
          />
        </div>
        <div className="bg-white p-10 h-full">
          <div className="w-full flex justify-end items-center">
            {Checked?.length > 0 && (
              <FaTrash
                onClick={() => {
                  setshowdeletefacmodal(true);
                }}
                className="text-primary mr-4"
                size={20}
              />
            )}
            <Button
              width={40}
              outlined
              text="Add Facility"
              onBtnClick={() => {
                setshowaddmodal(true);
              }}
            />
            <hr className="" />
          </div>
        

          {facilities.length > 0 ? (
            <div className="grid md:grid-cols-3 grid-cols-1 gap-6">
              {facilities.map((item, key) => (
                <FacilityItem
                  checklist={Checked}
                  checker={setchecked}
                  item={item}
                  key={key}
                />
              ))}{" "}
            </div>
          ) : (
            <NoFacility />
          )}
        </div>
      </div>
    </>
  );
};

export { AdminFacilities };
