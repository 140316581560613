import axios from "axios";
import { baseURL } from "../URL";

const onGetSchoolReview = async (Postdata) => {
  try {
    const response = await axios.post(`${baseURL}/getschoolreview`, Postdata);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};

const onGetAllReview = async ( ) => {
  try {
    const response = await axios.get(`${baseURL}/getallreview`);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onUpdateReviewItem = async (Postdata) => {
  try {
    const response = await axios.post(`${baseURL}/submitreviewitem`, Postdata);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onUpdateReview = async (Postdata) => {
  try {
    const response = await axios.post(`${baseURL}/setschoolreview`, Postdata);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};


 

export { onGetSchoolReview,onUpdateReview,onGetAllReview, onUpdateReviewItem };
