import { useForm } from "react-hook-form";
 import { toast } from "react-toastify";
import { useSelector } from "react-redux";
 import Button from "../../generic/Button";
import { onDeleteCert, onVerifyCert } from "../../../services/certificates";

const ConfirmApproval=(props)=>{

    const { control, handleSubmit } = useForm();

    const school_=useSelector((state)=>state.selectedSchool.schoolname)


const submit=async(data)=>{
 
const postdata={
    school:school_,
     index:props.selectedcert.key,
     title:props.selectedcert.title
}
 props.loading(true)
 
try{
    var res =await onVerifyCert(postdata)
    if(res.message){
        toast.success(res.message)
        props.refresh()
        props.showaddmodal(false)
        props.setcat(props.category)
    }

    props.loading(false)


}
    
catch(e){

console.log(e)
props.loading(false)


}

 

}
    return(<div className=" fixed w-[100vw] h-[100vh] top-0 left-0 flex bg-[#00000073] justify-center items-center ">

<div className='bg-white rounded w-[30vw] p-10 '>    


<h2 className='text-primary'>Do you want to validate this data for verification? </h2>
 

  


<div className='w-full  flex  gap-3 justify-end'> 

     
<Button  width={40} outlined text="Cancel" onBtnClick={()=>{   props.showaddmodal(false)}} />
<Button width={55}  text="Yes" onBtnClick={handleSubmit(submit)} />

</div>
</div>




        
    </div>)
}
export{ConfirmApproval}