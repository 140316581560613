import { dummy_image, idicon } from "../../constants/imports";

 function PhotoUpload (props){
   const onchange= (event)=>{
    const files = event.target.files;
    const file =(files[0]);
    props.hook.setfile(file)

    var img= document.getElementById(`${props.id}`);

    var reader = new FileReader();
    reader.onloadend = function() {
         img.src = reader.result;
    }
    reader.readAsDataURL(file);
    


   }

    const onclick=()=>{


        var input= document.getElementById(`${props.id}imageFile`)
        input. click()
    }

    return(<div className=" p-4  flex justify-center items-center flex-col" > 
      <input onChange ={(e)=>onchange(e)}
      className='hidden' id={`${props.id}imageFile`} type="file"/>   
      <img id={props.id} onClick ={onclick} className={`w-[75px] h-[75px]  opacity-50 bg-primary rounded  p-3 `} src={props. isdocument ? idicon :dummy_image} />
    <p onClick ={onclick} className="text-primary cursor-pointer text-sm font-bold mt-2">{props.title}</p>
    </div>)
}
export{PhotoUpload}