import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ComposeMsg from "./ComposeMsg";
import Spinner from "../../../Components/generic/Spinner";
import { MdCancel } from "react-icons/md";

export default ({ schools, show }) => {
  const [loading, setLoading] = useState(false);
  const dispatcher = useDispatch();
  const school_ = useSelector((state) => state.selectedSchool);
  var authuser = useSelector((state) => state.userStore);
  const navigate = useNavigate();

  return (
    <div className=" bg-[#0000004f] w-screen h-screen fixed flex justify-center items-center z-[300001] top-0 left-0">
      <Spinner loading={loading} />
      <div className="flex flex-col bg-gray-100 py-4 px-4 justify-center items-center">
        <div className="flex my-4 justify-between items-center w-full md:px-6 ">
          <p className="bg-white border inline text-xs font-semibold text-[#4677A1] border-gray-400 rounded p-2">
            Send Bulk Message to {schools.length} Schools
          </p>
          <MdCancel
            onClick={() => {
              show(false);
            }}
            size={20}
            className="text-primary  inline  text-xs mb-4 "
          />
        </div>
        <ComposeMsg schools={schools} isbulk={true} />
      </div>
    </div>
  );
};
