import { useEffect, useState } from "react"
import { useSelector } from "react-redux";

const useLocationHook=()=>{


   

    const [marker_pos, setmarkerpos]=useState([7.77589,4.55473])
    useEffect(()=>{
 

    },[])
 

    return {marker_pos, setmarkerpos}
}

export{useLocationHook}