import { useDispatch, useSelector } from "react-redux";
 import { useEffect, useState } from "react";
 import { onGetAllfacility } from "../../services/facility";
 import { FaTrash } from "react-icons/fa";
import { PiEmptyThin } from "react-icons/pi";
 import { onGetAllMessages } from "../../services/user";
import { toast } from "react-toastify";
import MessageItem from "./message/MessageItem";
import Spinner from "../../Components/generic/Spinner";
import { removeNonLetters } from "../../Utils/govfunctions";
import { FaArrowLeft } from "react-icons/fa6";
import { CiInboxIn, CiInboxOut } from "react-icons/ci";
 import ComposeMsgRe from "./message/ComposeMsgRe";
import { IoIosRefresh } from "react-icons/io";
import Button from "../../Components/generic/Button";

export default () => {
  const dispatcher = useDispatch();
  const [loading, setLoading] = useState(false);
  const facilities = useSelector((state) => state.facilities);
  const [Checked, setchecked] = useState([]);
  const [renderedlist, setrenderedlist] = useState([]);
  const [selectedmsg, setselectedmsg] = useState({});
  const [showreply, setshowreply] =useState(false)
  const [showdeatilsmsg, setshowdeatilsmsg] = useState(false);
  const school_=useSelector((state)=>state.selectedSchool)
  const [messages, setmessages] = useState([]);
  const [showdeletefacmodal, setshowdeletefacmodal] = useState(false);
  var authuser = useSelector((state) => state.userStore);
 
  const [active, setactive] = useState("All");
  const [selectbedfac_, setselectedfac_] = useState({});

  var school = school_.schoolname

  const NoMessage = () => (
    <div className="flex  w-full flex-col justify-center items-center  p-4">
      <PiEmptyThin className="text-primary" size={30} />
      <p className="text-primary font-light text-2xl ">No Messages yet</p>
    </div>
  );
  
  
  const ViewMsg = () => (<>
  
 
    <div className="mx-10 pt-10">
      <div className="bg-white text-xs p-2 ">
        <div className="flex ">
          {selectedmsg.issent ? (
            <CiInboxOut size={30} className=" inline text-primary font-light" />
          ) : (
            <CiInboxIn size={30} className=" inline text-primary font-light" />
          )}
          <div className="flex flex-col">
            <p className=" text-xs font-medium mx-4 ">
              From {selectedmsg.user ? selectedmsg.user : selectedmsg.from}{" "}
              {selectedmsg.senderType} to {selectedmsg.to}
            </p>
            <p className="text-[10px] font-light mx-4 ">{selectedmsg.time}</p>
          </div>
        </div>
        <p>
          <FaArrowLeft
            className=" ml-3 m-3 mb-3 inline  text-primary"
            size={20}
            onClick={() => setshowdeatilsmsg(false)}
          />
          Sent from{" "}
          <span className="bg-white border text-xs font-semibold text-primary border-gray-400 rounded p-2">
            {selectedmsg.from}
          </span>
        </p>

        <p className="text-gray-500 text-lg mx-4 ">{selectedmsg.title}</p>
        <p className="text-gray-500 text-md mx-4 bg-gray-100 p-3 ">
        {selectedmsg.content?selectedmsg.content:selectedmsg.message}
        </p>

      </div>
      <div className="w-full  flex  gap-3 justify-end">
        <Button width={55} text="Reply" onBtnClick={() => {setshowreply(true)}} />
      </div>

    </div>
    {showreply&&   <ComposeMsgRe show={setshowreply} message={selectedmsg} />}
    </>
  );
  const getallmsg = async () => {
    let postdata = { user: removeNonLetters(authuser.email) };
    setLoading(true);
    try {
      var response = await onGetAllMessages(postdata);
      console.log(response.data);
      var emptarr = [];
      for (let messages in response.data) {
        emptarr.push(response.data[messages]);
      }

      setmessages(emptarr);
    } catch (e) {
      toast.error(e);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

 
  const FilterItem = ({ item, click }) => (
    <div
      onClick={() => {
        setactive(item);
        click();
      }}
      className={`${
        active == item
          ? "bg-primary font-semibold flex justify-center items-center text-white"
          : "  border border-gray-300"
      } cursor-pointer rounded  text-sm p-2  mx-3`}
    >
      {item}
    </div>
  );

  useEffect(() => {
    messages.reverse()
    setrenderedlist(messages);
  }, [messages]);



  useEffect(() => {
    console.log(messages, "gg..");
  }, [messages]);

  useEffect(() => {
    getallmsg();
  }, []);
  dispatcher({ type: "set-pagetitle", payload: "Messages" });
  var [showaddmodal, setshowaddmodal] = useState(false);

  useEffect(() => {
    console.log(Checked);
  }, [Checked]);

  return (
    <>
      {!showdeatilsmsg ? (
        <>
          <Spinner loading={loading} />
          <div className="border flex border-gray-400  p-2 mx-10 items-center">
           
            <FilterItem
              click={() => {
                setrenderedlist(messages);
              }}
              item="All"
            />
            <FilterItem
              click={() => {
                setrenderedlist(messages.filter((n) => !n.issent));
              }}
              item="Inbox"
            />
            <FilterItem
              click={() => {
                setrenderedlist(messages.filter((n) => n.issent));
              }}
              item="Sent messages"
            />
            <IoIosRefresh onClick={async ()=>{


getallmsg()
            }} size={20} className="inline text-primary"/>
          </div>
          <div className="p-10 bg-gray-100 border border-gray-500 mx-10 h-[70vh] overflow-y-scroll overflow-x-hidden">
            {renderedlist .length > 0 ? (
              <div className="">
                {renderedlist.map((item, key) => (
                  <MessageItem
                    click={() => {
                      setselectedmsg(item);
                      setshowdeatilsmsg(true);
                    }}
                    key={key}
                    item={item}
                  />
                ))}
              </div>
            ) : (
              <div className="bg-white p-10 h-full">{<NoMessage />}</div>
            )}
          </div>
        </>
      ) : (
        <ViewMsg />
      )}
    </>
  );
};
