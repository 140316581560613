import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Fragment, useState } from 'react'
import InputField from '../generic/Inputfield'
import { textReg } from '../../Utils/regex'
import { useForm } from 'react-hook-form'
import Button from '../generic/Button'
import { PhotoUpload } from '../generic/PhotoUpload'
import { usePhotoUpload } from '../../Hooks/usePhotoUploadhook'
import { usePickerhook } from '../../Hooks/usePickerhook'
import { Categorypicker } from './Categorypicker'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { onAddFacility } from '../../services/facility'
import Spinner from '../generic/Spinner'

 
export default function AddFacilitymodal(props) {




var categorypickerhook= usePickerhook()
const [loading, setLoading] = useState(false);

 var authuser =useSelector(state=>state.authuser)
    var choosephotohook = usePhotoUpload()

    const { control, handleSubmit } = useForm();
    const submit=async (data)=>{
if(!choosephotohook.file){


  toast.error('Photo of facility is required')
return

}


if(!categorypickerhook.pickedRole){

  toast.error('A category is required')
return
  
}





      const formdata = new FormData()
      formdata.append(  'title', data.title)
      formdata.append(  'school',props.school )
      formdata.append('user',authuser.email)
    
      formdata.append(  'facilityphoto', choosephotohook.file)
      formdata.append(  'type', categorypickerhook.pickedRole.type)


      setLoading(true)
      var res = await onAddFacility(formdata)
      setLoading(false)
        toast(res?res.message:" An error occured")
        props.showaddmodal(false)


    
    }

  return (

<div className=' bg-[#000000d3] w-full h-full fixed z-[1000] flex  top-0 left-0 justify-center items-center'>




<Spinner loading={loading} />

<div className='bg-white rounded w-[80vw] md:w-[30vw] p-10 '>

<h2 className='text-primary'>Add Facility</h2>
<InputField
        name="title"
        title="Title"
        placeholder="Enter a title"
        control={control}
        rules={{
          required: "Title is required",
          pattern: {
            value: textReg,
            message: "Invalid title",
          },
        }}
      />

<Categorypicker hook ={categorypickerhook}/>
      <div className='w-full flex justify-center items-center'>
       <PhotoUpload hook={choosephotohook} />
       </div>
      <div className='w-full  flex  gap-3 justify-end'> 

     
       <Button  width={40} outlined text="Cancel" onBtnClick={()=>{   props.showaddmodal(false)}} />
       <Button width={55}  text="Add" onBtnClick={handleSubmit(submit)} />

 </div>
</div>

</div>
  )
}
 
 