import { MdClose } from "react-icons/md"
  import { useDispatch, useSelector } from "react-redux";
 import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
 import { useState } from "react";
import Spinner from "../generic/Spinner";
import Button from "../generic/Button";
import { onDeleteSchool } from "../../services/school";

export default ({show})=>{
  const userschool=useSelector((state)=>state.selectedSchool)
const navigate =useNavigate()
const [loading, setLoading] = useState(false);
const authuser = useSelector((state) => state.authuser);
const dispatcher=useDispatch()
  return(
  <div className=" w-screen h-screen fixed top-0 left-0 bg-[#000000b5] flex justify-center items-center overflow-y-scroll overflow-x-hidden">
         <Spinner loading={loading} />

   
    <div className="w-[40%] relative mt-4 flex flex-col justify-center items-center">
      <MdClose onClick={()=>{

        show (false)
      }} size={20} className="absolute  flex justify-center items-center top-5 right-5" />
   <div className="bg-white p-4 rounded flex justify-center items-center flex-col px-10">
<p className="text-primary font-semibold">Are you sure you want to delete this school?</p>
<p className="text-sm font-light"> When a school is deleted, all data for this account will be reset and you may need to create a new school again</p>
         
         
         <div className="flex gap-4 self-end">
         <Button
            width={40}
            outlined
            text="Cancel"
            onBtnClick={() => {
              show(false);
            }}
          />
          <Button width={55}  text="Delete" onBtnClick={async()=>{


try{

  setLoading(true)
  var res=await onDeleteSchool({schoolname:userschool.schoolname, user:userschool.user})
  toast(res)
   show(false)
  setLoading(false)


     navigate("/dashboard/allschools");
  
}

catch(e){
console.log(e)
toast(e)

}

          }} />
          </div>
          
      
        </div>
   </div>
    </div>
 )
}

