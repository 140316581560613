import { removeNonLetters } from "../../Utils/govfunctions";

const GroupQuestionItem = (props) => {



  
  return (
    <div className="bg-white p-2 ">
      <p className="text-lg  ">
        {" "}
        {props.key_ + 1} : {props.item.title}{" "}
        <span className="text-xs ml-2 ">({props.item.score})</span>
      </p>

      { props.item.questions.map((item)=>(<p className="m-2 font-light">
        <span className="p-2 rounded bg-faint inline-block  outline-0">
          <input
            name={props.item.title}
            onChange={() => {
              props.onChange({ [removeNonLetters(props.item.title)]: item.score });
            }}
            type="radio"
          />
        </span>{" "}
      {  item.title}
      </p>))}
      
      
    </div>
  );
};

export { GroupQuestionItem };
