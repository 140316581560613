import Button from "../../Components/generic/Button";
import { FiAlertTriangle } from "react-icons/fi";


export default (props) => (
  <div className=" fixed w-[100vw] h-[100vh] top-0 left-0 flex z-50 bg-[#00000073] justify-center items-center ">
    <div className="bg-white rounded min-w-[30vw] flex flex-col justify-center items-center  max-h-[80vh] p-10   ">

    <FiAlertTriangle  className ="text-primary" size={40} />


<p className="text-center font-light m-2">Changed school will be moved to your past employment records  </p>


<div className="w-full  flex  gap-3 justify-end">
          <Button
            width={40}
            outlined
            text="Cancel"
            onBtnClick={() => {
              props.setpre(false);
            }}
          />
          <Button width={55} text="Proceed" onBtnClick={()=>{


props.setpre(false);

props.proceed(true)

          }} />
        </div>
    </div>
  </div>
);
