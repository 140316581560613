import axios from "axios";
import { baseURL } from "../Utils/URL";

const onGetUser = async (postData) => {
    try {
        const response = await axios.post(`${baseURL}/getuser`, postData);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
}

const onVerifyuser = async (postData) => {
    try {
        const response = await axios.post(`${baseURL}/verifyuser`, postData);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
}
const onUpdateProprietor = async (postData) => {
    try {
        const response = await axios.post(`${baseURL}/updateschoolprop`, postData);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
}
const onGetProprietor = async (postData) => {
    try {
        const response = await axios.post(`${baseURL}/getschoolprop`, postData);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
}
const onSendEmail = async (postData) => {
    try {
        const response = await axios.post(`${baseURL}/sendemailverification`, postData);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
}

const onGetAllusers= async (postData) => {
    try {
        const response = await axios.get(`${baseURL}/getallusers`);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};

const onGetAllstaffs= async (postData) => {
    try {
        const response = await axios.get(`${baseURL}/getstaffs`);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const onChangeuserPass = async (postData) => {
    try {
        const response = await axios.post(`${baseURL}/changepassword`, postData);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
}


const onGetAllMessages= async (postData) => {
    try {
        const response = await axios.post(`${baseURL}/getmessages`,postData);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};

const onUpdateUser= async (postData) => {
    try {
        const response = await axios.post(`${baseURL}/updategovstaff`,postData);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};


export {onGetUser,onSendEmail, onUpdateUser, onGetAllMessages, onChangeuserPass,onGetAllstaffs,onGetProprietor, onUpdateProprietor, onGetAllusers, onVerifyuser}