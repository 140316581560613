import { Menu, Transition } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { IoIosSearch } from "react-icons/io";
import Spinner from "../generic/Spinner";

export default ({ placeholder, value, onchange }) => {
  const [focused, setfocused] = useState(false);
   const searchcatheadstyle = "text-sm text-black text-gray-500 font-medium mt-4 mb-2 font-semibold ";
  const containerstyle = "my-2  w-full";

  return (
    <div
      className=" flex flex-col w-full relative z-[300000]"
      onClick={() => {}}  > 
       <div
        className={`flex justify-center  relative items-center border-2 ${
          focused ? " border-primary  " : " border-gray-200"
        }  bg-white rounded-lg  `}  >
        <IoIosSearch size={20} className="ml-4 text-textbg" />

        <input
          type={"text"}
          value={value}
          placeholder={placeholder}
          onChange={(e) => {
            onchange(e.target.value);
          }}
          className={` custom-outline  block bg-white text-xs py-3 custom-outline	px-4 rounded-lg   w-full shadow-xs text-textbg `}
        />
      </div>
    </div>
  );
};
