import { useState } from "react"

const usePickerhook=()=>{

var [pickedRole, setPickedRole]=useState ()



    return {pickedRole,setPickedRole}
}

export {usePickerhook}