import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Spinner from "../../Components/generic/Spinner";
import { Navbar } from "../../Components/ui/Navbar";
import { onGetAllSchool } from "../../Utils/Servicess/school";
import { all } from "axios";
import { SideBar } from "../../Components/generic/Sidebar";

const Dashboard = () => {
  const authuser = useSelector((state) => state.userStore);
  const pageTitle = useSelector((state) => state.pageTitle);
  var location = useLocation();
  const dispatcher = useDispatch();
  var navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 644px)",
  });


  useEffect(()=>{
    dispatcher({type:'set-pagetitle',payload:"Gov. Staff profile dashboard"})

  },[])
  useEffect(() => {
    // if (isDesktopOrLaptop) {
    //   console.log("isdesktop");
      if (!authuser?.firstname) {
        navigate("/");
        return;
      }


    // } else {
    //   console.log("not desktop");
    //   navigate("/mobile");
    // }
  }, [authuser?.school]);


  return (
    <div className="w-full h-[85vh]    flex">
      <Spinner loading={loading} />
      <div className="md:w-[18vw] ">
      { isDesktopOrLaptop&&  <SideBar />}
      </div>

      <div className=" md:w-[82vw] w-full h-[100vh] self-start bg-[#F3F2F7] ">
   <Navbar />
        <p className="text-[#00000090] font-semibold  m-4 self-start">
        {pageTitle}
        </p>
        <Outlet className=" bg-primary  w-full h-[100vh]" />
      </div>
    </div>
  );
};

export { Dashboard };
