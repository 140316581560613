import axios from "axios";
import { baseURL } from "../Utils/URL";

const onGetAllcertificates = async (postData) => {
    try {
        const response = await axios.post(`${baseURL}/getcerts`, postData);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const onDeleteCert = async (postData) => {
    try {
        const response = await axios.post(`${baseURL}/deletecert`, postData);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const onVerifyCert = async (postData) => {
    try {
        const response = await axios.post(`${baseURL}/verifycertificate`, postData);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};



const onUpdateCert= async (formdata)=>{



    try {
        const response = await axios.post(`${baseURL}/updatecert`, formdata, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }});
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }

}


export{onGetAllcertificates,onDeleteCert, onVerifyCert, onUpdateCert}