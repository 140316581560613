const user = localStorage.getItem(`GovstaffUserData`);

const userobj = JSON.parse(user ?? "{}");
console.log(userobj);

const defaultState = {
  staffSchool: {},
  selectedSchool:{},
  selectedReviewItemKey:0,
  facilities:[],
  selectedStaff:{},
  pageTitle:"",
  selectedReviewItem:{},
  reviewIsViewonly:false,
  userStore: userobj,
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {

    case "set-school":
      return{...state, staffSchool:{...action.payload} }
    case "setSelected-review-item":
      return{...state, selectedReviewItem:{...action.payload} }

      case "set-reviewIsViewonly":
      return{...state, reviewIsViewonly:action.payload }

    case "setSelected-review-itemKey":
      return{...state, selectedReviewItemKey:action.payload }
      
    case "set-selectedSchool":
      return{...state, selectedSchool:{...action.payload} }
    case "set-selectedStaff":
      return{...state, selectedStaff:{...action.payload} }

    case "set-user":
      return { ...state, userStore: { ...action.payload } };
    case "set-pagetitle":
      return { ...state, pageTitle:action.payload  };
    case "set-facilities":
      return { ...state, facilities:[...  action.payload  ]};
    default:
      return { ...state };
  }
};

export { reducer };
