import React, { FC } from "react";
 
const Spinner = ({ loading }) => {
  return (
    <>
      {loading && (
        <div className="loadingSpinnerContainer">
          <div className="loadingSpinner"></div>
        </div>
      )}
    </>
  );
};

export default Spinner;
