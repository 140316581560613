import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { onGetAllfacility } from "../../services/facility";
import { FaTrash } from "react-icons/fa";
import { PiEmptyThin } from "react-icons/pi";
import { onGetAllMessages } from "../../services/user";
import { toast } from "react-toastify";
import MessageItem from "./message/MessageItem";
import Spinner from "../../Components/generic/Spinner";
import { removeNonLetters } from "../../Utils/govfunctions";
import { FaArrowLeft } from "react-icons/fa6";
import { CiInboxIn, CiInboxOut } from "react-icons/ci";
import ComposeMsgRe from "./message/ComposeMsgRe";
import { IoIosRefresh } from "react-icons/io";
import Button from "../../Components/generic/Button";

export default () => {
  const dispatcher = useDispatch();
  const [loading, setLoading] = useState(false);
  const facilities = useSelector((state) => state.facilities);
  const [Checked, setchecked] = useState([]);
  const [renderedlist, setrenderedlist] = useState([]);
  const [showdeatilsmsg, setshowdeatilsmsg] = useState(false);
  const school_ = useSelector((state) => state.selectedSchool);
  const [messages, setmessages] = useState([]);
  var authuser = useSelector((state) => state.userStore);

  const [active, setactive] = useState("All");

  const getallmsg = async () => {
    let postdata = { user: removeNonLetters(authuser.email) };
    setLoading(true);
    try {
      var response = await onGetAllMessages(postdata);
      console.log(response.data);
      var emptarr = [];
      for (let messages in response.data) {
        emptarr.push(response.data[messages]);
      }

      setmessages(emptarr);
    } catch (e) {
      toast.error(e);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const FilterItem = ({ item, click }) => (
    <div
      onClick={() => {
        setactive(item);
        click();
      }}
      className={`${
        active == item
          ? "bg-primary font-semibold flex justify-center items-center text-white"
          : "  border border-gray-300 "
      } cursor-pointer rounded  font-semibold text-sm p-2  mx-3`}
    >
      {item}
    </div>
  );

  useEffect(() => {
    messages.reverse();
    setrenderedlist(messages);
  }, [messages]);

  useEffect(() => {
    console.log(messages, "gg..");
  }, [messages]);

  useEffect(() => {
    getallmsg();
  }, []);
  dispatcher({ type: "set-pagetitle", payload: "Evalutaion" });

  useEffect(() => {
    console.log(Checked);
  }, [Checked]);

  return (
    <>
      <Spinner loading={loading} />
      <div className="border flex border-gray-400  p-2 mx-10 items-center justify-between md: w-[50%]">
        <FilterItem
          click={() => {
            setrenderedlist(messages);
          }}
          item="LIEs"
        />
        <FilterItem
          click={() => {
            setrenderedlist(messages.filter((n) => !n.issent));
          }}
          item="ZEs"
        />
        <FilterItem
          click={() => {
            setrenderedlist(messages.filter((n) => n.issent));
          }}
          item="HQ officials"
        />
        <IoIosRefresh
          onClick={async () => {
            getallmsg();
          }}
          size={20}
          className="inline text-primary"
        />
      </div>
    </>
  );
};
