import { useSelector } from "react-redux";

const SelectedHero = (prp) => {
  var props = useSelector((state) => state.selectedSchool);
  var userschool = useSelector((state) => state.selectedSchool);
  var categories = props.schoolcategories?.split(",");

  return (
    <div
      style={{
        backgroundImage: `url(${
          userschool.coverphoto == ""
            ? "https://firebasestorage.googleapis./v0/b/sclverse.appspot.com/o/predefs%2Fcoverphoto.jpg?alt=media&token=447e7c08-19fd-4d9f-ac87-679bd0039793"
            : userschool.coverphoto
        })`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
      className=" p-4 rounded coverphotoholder md:flex md:h-[250px] "
    >
      <img className="rounded-full" width={200} src={props.schoollogo} />
      <h1 className="font-bold text-white p-3 bg-[#4444443a] text-lg py-8 px-4">
        {props.schoolname}
      </h1>
      <div className=" md:flex  justify-end items-center bg-[#0808088f] ">
        <div className="flex justify-center flex-col p-1 bg-[#ffffff44] m-3 rounded items-center">
          <p className="text-white font-light">Number of Teaching Staff</p>
          <p className="text-white  text-xl font-semibold">{prp.stf}</p>
        </div>
        <div className=" flex justify-center flex-col rounded p-1 bg-[#ffffff44] m-3 items-center">
          <p className="text-white font-light ">Number of Student</p>
          <p className="text-white text-xl font-semibold">{prp.std}</p>
        </div>
     
      </div>

      <div className="p-4 text-xs text-white font-light bg-primary justify-center items-center ">
        <p>
          {" "}
          Year founded:
          {props.yearfounded}{" "}
        </p>
        <br />
        <p>
          {" "}
          School type:
          {props.schooltype ? props.schooltype : "..."}{" "}
        </p>
        <br />
        <p>School categories :</p>
        <br />
        {categories?.map((item) => (
          <p className="inline p-2 rounded mx-2 bg-white font-light  text-black ">
            {item}
          </p>
        ))}
      </div>

     
    </div>
  );
};
export { SelectedHero };
