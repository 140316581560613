 import { FaTrash } from "react-icons/fa6";


const FacilityItem=(props)=>{

const checkerChange=(event)=>{
if (event.target.checked){
    props.checker((state)=>[props.item.title].concat( state)  )
 
}
else{

    props.checker((state)=>[].concat(state.filter((item)=>item!=props.item.title)))
 

}


}

    return(<div className=" w-[250px]  bg-white shadow" ><img className=" h-[170px] w-full"   src={props.item.facilityphoto} />
    
    
    <div className="flex justify-between p-3">
        
        
        <div>
            <p className="font-semibold  text-black text-sm" >{props.item.title}</p>
    <p className="text-primary font-light text-xs">{props.item.type}</p>
    </div>
  <input  onChange={checkerChange} name="check" type="checkbox" />

    </div>
 

    </div>)
}

export{FacilityItem}