import { useNavigate } from "react-router-dom"

const SchoolNavItems =(props)=>{
var navigate=useNavigate()



    return(<div onClick={()=>{
        navigate(props.item.path)

    }} className="bg-primary rounded shadow px-2 mx-4 py-8 flex h-[50px] justify-center items-center">


<p className="text-md font-light text-white  ">{props.item.title}</p>

    </div>)
}

export{SchoolNavItems}