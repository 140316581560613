import React, { useState, useRef, useEffect } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { marker as markerIcon } from "../../constants/imports";
import Button from "../generic/Button";
import { useSelector } from "react-redux";
// import { onUpdateLocation } from "../../services/school";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const MapComponent = (props) => {
  const mapRef = useRef(null);
  const [editmap, seteditmap]=useState(false)
var navigate=useNavigate()



  useEffect(()=>{


    if(props.userschool?.schoolname==undefined){
      navigate('/dashboard')
      console.log(props.userschool?.schoolname,".....")
  
  
    }

    else{

      console.log("nill..",".....")


    }
  },[])
 

  const getschoolloaction = () => {
    const catArray = props.userschool?.location?.split(",");
    return catArray;
  };
 
//   const savelocation = async (newpos) => {
//     var postdata = {
//        newloaction: newpos.join(','),
//        school:props.userschool?.schoolname
//  };
// props.setLoading(true)
//  var res = await onUpdateLocation(postdata)
//  toast.success(res.message)
//  props.setLoading(false)
//  console.log(res)
//   };

  const cord =
    getschoolloaction()?.length == 2
      ? getschoolloaction()
      : props.Hook.marker_pos;

  const [marker_pos, setmarkerpos] = useState(cord);

  const [marker, setMarker] = useState(null);
  useEffect(() => {
    // Initialize map

    const map = L.map(mapRef.current).setView(marker_pos, 13);

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 18,
    }).addTo(map);

    // Add custom marker icon
    const customIcon = L.icon({
      iconUrl: markerIcon, // Path to the marker icon image
      iconSize: [29, 40], // Size of the icon
      iconAnchor: [16, 32], // Point of the icon which will correspond to marker's location
      popupAnchor: [0, -32], // Point from which the popup should open relative to the iconAnchor
    });

    // Add marker to map with custom icon
    const newMarker = L.marker(marker_pos, {
      draggable: true,
      icon: customIcon,
    }).addTo(map);
    setMarker(newMarker);

    // Update marker position on drag
    newMarker.on("dragend", (event) => {
      const { lat, lng } = event.target.getLatLng();
      console.log("New marker position:", lat, lng);

      setmarkerpos([lat, lng]);
      //props.Hook.setmarkerpos([lat, lng]);
    });

    // Cleanup
    return () => {
      map.remove();
    };
  }, [props.userschool]);

  return (
    <>
      {" "}

    {!editmap &&  <iframe
  
  style={{ height: `${props.isviewonly ? "200px" : "400px"} ` }}  
  className="border-2 rounded border-primary w-full mt-4 "
  loading="lazy"
  allowfullscreen
  referrerpolicy="no-referrer-when-downgrade"
  
  src={"https://www.google.com/maps?q="+marker_pos[0]+","+marker_pos[1]+"&hl=es;z=14&output=embed"}>
</iframe>
}

      <div
        ref={mapRef}
        style={{ height: `${editmap ? "200px" : "0px"}` }}
      />
      {!props.isviewonly && ( 
        <>
          {" "}
          <div className="w-full  bg-faint p-4 flex">
            <p className="text-sm  text-primary w-full inline">
              <span className="text-black f">Lattitude:</span>
              {marker_pos[0]}
            </p>

            <p className="text-sm  text-primary w-full inline">
              <span className="text-black"> Longitude:</span>
              {marker_pos[1]}
            </p>
          </div>
          <div className="w-full flex gap-8 px-8">
      

 
           
          </div>
        </>
      )}
    </>
  );
};

export default MapComponent;
