import { useForm } from "react-hook-form";
import Button from "../generic/Button";
import { PhotoUpload } from "../generic/PhotoUpload";
import { ListPicker } from "../ui/ListPicker";
import { usePhotoUpload } from "../../Hooks/usePhotoUploadhook";
import { usePickerhook } from "../../Hooks/usePickerhook";
import { toast } from "react-toastify";

import "react-datepicker/dist/react-datepicker.css";

import { onUploaddocument } from "../../Utils/Servicess/user";
import { useState } from "react";
import Spinner from "../generic/Spinner";

const StepTwo = (props) => {
  const passportuploadhook = usePhotoUpload();
  const [loading, setLoading] = useState(false);
  const genderpicker = usePickerhook();
  const religionpicker = usePickerhook();
  const IDuploadhook = usePhotoUpload();

  const { control, handleSubmit } = useForm();
  const onSubmit = async (data) => {
    if (!genderpicker.pickedRole) {
      toast.error("Gender is required");
      return;
    }
    // if (!religionpicker.pickedRole) {
    //   toast.error("Religion is empty");
    //   return;
    // }

    if (!passportuploadhook.file) {
      toast.error("Passport Photograph is required");
      return;
    }

    // if (!IDuploadhook.file) {
    //   toast.error("ID is required");
    //   return;
    // }

    //Upload id, return urls for each set to formpostdata the register user
    //Confirm

    var idpostdata = new FormData();
    idpostdata.append("id", IDuploadhook.file);
    idpostdata.append("email", props.postdata.email);
    idpostdata.append("name", "id");

    var passportpostdata = new FormData();
    passportpostdata.append("passport", passportuploadhook.file);
    passportpostdata.append("email", props.postdata.email);
    passportpostdata.append("name", "Passport");

    setLoading(true);

    var formdata = { iddoc: "", passport: "" };

    var iduploadres = await onUploaddocument(idpostdata);

    if (IDuploadhook.file) {
      if (iduploadres.success) {
        formdata = { ...formdata, iddoc: iduploadres.url };
      } else {
        toast.error("Could not upload document");
        setLoading(false);
        return;
      }
    }

    var passportupres = await onUploaddocument(passportpostdata);

    if (passportupres.success) {
      formdata = { ...formdata, passport: passportupres.url };
      setLoading(false);
    } else {
      toast.error("Could not upload document");
      setLoading(false);

      return;
    }

    props.setpostdata({
      ...props.postdata,
      ...formdata,
      gender: genderpicker.pickedRole
     });

    props.nextStep();
    props.setCurrentStepIndex(2);
  };

  return (
    <div className="bg-white">
      <Spinner loading={loading} />

      <p className="m-3 font-semibold text-primary">More details</p>

      <div className=" flex flex-col ">
        <ListPicker
          title="Gender"
          hook={genderpicker}
          list={["Male", "Female"]}
        />
        {/* <ListPicker
          title="Religion"
          hook={religionpicker}
          list={["Christianity", "Islam", "Others"]}
        /> */}

        <hr />
        <PhotoUpload
          id="passport"
          title="Passport Photograph"
          hook={passportuploadhook}
        />
        <hr />
        <PhotoUpload id="id" isdocument={true} title="ID (Optional)" hook={IDuploadhook} />

        <div className=" flex gap-4 ">
          <Button
            outlined
            text="Previous"
            onBtnClick={() => {
              props.setCurrentStepIndex(0);
              props.previousStep();
            }}
          />
          <Button text="Next" onBtnClick={handleSubmit(onSubmit)} />
        </div>
      </div>
    </div>
  );
};

export { StepTwo };
