const Categoryitem=(props)=>{


    return(<div onClick={()=>{
        props.changeactive(props.item)
    }} className={` flex justify-center rounded px-1 py-2 ${props.active? 'bg-primary text-white font-semibold':'bg-white text-black'}`}>
<p className=" cursor-pointer   text-sm">{props.item}</p>
    </div>)
}

export {Categoryitem}