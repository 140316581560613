import { Fragment, useEffect, useState } from "react";

import Button from "../generic/Button";
import { PhotoUpload } from "../generic/PhotoUpload";
import { usePhotoUpload } from "../../Hooks/usePhotoUploadhook"
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Spinner from "../generic/Spinner";
import { onUpdateCert } from "../../Utils/Servicess/certificate";

export default function ViewCert(props) {
  const [loading, setLoading] = useState(false);
  var authuser = useSelector((state) => state.authuser);
  const [selectedcert,setselectedcert]=useState({})
  var choosephotohook = usePhotoUpload();

  useEffect(()=>{

    props.certificates.map((i,key)=>{


if(i.title==props.selectedcert){
  setselectedcert(i)
}
 
    })

  },[props.selectedcert])

  const submit = async (data) => {
    if (!choosephotohook.file) {
      toast.error("Photo of certificate is required");
      return;
    }

    const formdata = new FormData();
    formdata.append("school", authuser.school);
    formdata.append("certphoto", choosephotohook.file);
    formdata.append("index", props.selectedcert);

    setLoading(true);
    var res = await onUpdateCert(formdata);
    setLoading(false);
    toast(res ? res.message : " An error occured");
    props.refresh();
    props.showaddmodal(false);
  };





  return (
    <div className=" bg-[#000000d3] w-full h-full fixed z-[1000] flex  top-0 left-0 justify-center items-center">
      <Spinner loading={loading} />

      <div className="bg-white flex flex-col rounded min-w-[30vw] p-10 justify-center items-center">
        <h2 className="text-primary">Uploaded certificate </h2>

        <div className="w-full flex justify-center items-center">
          <img
            className="w-[200px] h-[200px]"
            src={selectedcert?.url}
          />
        </div>

        <div className="w-full  flex  gap-3 justify-center">
          <Button
            width={40}
            outlined
            text="Close"
            onBtnClick={() => {
              props.showaddmodal(false);
            }}
          />
        </div>
      </div>
    </div>
  );
}
