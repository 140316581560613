import { useState } from "react";
 


const usePhotoUpload =()=>{

    var [file, setfile]=useState()

    const refreshfile=()=>{

        setfile('')
    }



    return {file, setfile, refreshfile}
}


export {usePhotoUpload}