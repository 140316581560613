import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Categoryitem } from "../../Fees/CategoryItem";
import { onGetAllFees } from "../../../services/fees"; 

import Spinner from "../../generic/Spinner";
import { FeesItem } from "../../Fees/FeesItem";
import { GiClick } from "react-icons/gi";
import { toast } from "react-toastify";
import { EditFee } from "../../Fees/EditFee";
import { ConfirmDelete } from "../../Fees/DeleteConfirm";
import Button from "../../generic/Button";
import { AddFeeModal } from "../../Fees/AddFeeModal";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
const headeritems = ["Fee", "Amount(₦)"];

const Admin_Fees = () => {
  const dispatcher = useDispatch();
  const [loading, setLoading] = useState(false);

   dispatcher({ type: "set-page-title", payload: "Fees" });
   const [refresh, setrefresh] = useState(0);
  var [selectedfees, setSelected] = useState([]);
  const [categories, setCategory] = useState([]);
  const [fees, setfees] = useState({});
  const [showaddfeemodal, setshowaddfeemodal] = useState(false);
  const navigate =useNavigate()
  const [showaddnewfeemodal,setshowaddnewfeemodal] =useState(false)
  const [showdeletefeemodal, setshowdeletefeemodal] = useState(false);
  const [selectbedfee_, setselectedfee_] = useState({});
  const [active, setactivve] = useState(categories[0]);
  const school_=useSelector((state)=>state.selectedSchool)


  const getfees = async () => {
    const postdata = {
      school: school_.schoolname,
    };

    try {
      setLoading(true);
      var res = await onGetAllFees(postdata);
      setLoading(false);
      if (res.fees) {
        setfees(res.fees);

        let array = [];
        for (let category in res.fees) {
          array.push(category);
        }

        setCategory(array);
      }

      setSelected(Xitems());

    console.log(selectedfees);
    } catch (e) {
      toast.error(e);
    }
  };

  const Xitems = () => {
    var items = [];
    for (let k in fees[active]) {
      let title = k;
      let ammount = fees[active][k];
      var c = { title: title, ammount: ammount };
      items.push(c);
    }

    return items;
  };

  useEffect(() => {

    if(school_.schoolname==undefined){
        navigate('/admin')
       return
       }
  getfees();

  }, [active, refresh]);



  
  return (
    <div className="bg-customGray h-full p-10 ">
      {showaddfeemodal && (
        <EditFee

        school={school_.schoolname}
          refresh={() => {
            console.log("refreshed");
            setrefresh(refresh + 1);
          }}
          setcat={setactivve}
          category={active}
          loading={setLoading}
          fee={selectbedfee_}
          showaddmodal={setshowaddfeemodal}
        />
      )}
   {showaddnewfeemodal &&   <AddFeeModal  
           school={school_.schoolname}
           refresh={() => {
            console.log("refreshed");
            
            setrefresh(refresh + 1);
          }}
          category={active}
          setcat={setactivve}
          loading={setLoading}
          fee={selectbedfee_}
          showaddmodal={setshowaddnewfeemodal} />}


      {showdeletefeemodal && (
        <ConfirmDelete
        setcat={setactivve}
        fee={selectbedfee_}
        school={school_.schoolname}

          category={active}
          loading={setLoading}
          
          showaddmodal={setshowdeletefeemodal}
        />
      )}
      <Spinner loading={loading} />
<div className="flex   items-center">

      <FaArrowLeft className="m-3 mb-3 text-primary" size={30} onClick={()=>navigate(-1)}/><h1>{school_.schoolname} Fees</h1>
      </div>
      <div className="bg-white flex w-full items-center p-4 justify-start flex-col  h-full">
        <div className="w-[70%] mx-4 border grid grid-cols-3 border-black rounded px-2 py-1">
          {categories.map((item) => (
            <Categoryitem
              changeactive={setactivve}
              active={active == item}
              item={item}
            />
          ))}
        </div>
        <p className="text-primary inline my-2.5  text-xs font-light">
          {" "}
          <GiClick className="text-primary inline" size={20} /> Tap each
          category to view and edit fees
        </p>
        <div className=" flex justify-end items-end  w-full">
        <Button isdisabled={active==undefined?true:false}  width={55}  text="Add fee" onBtnClick={()=>{   setshowaddnewfeemodal(true)  }} />
        </div>
        {/* Table Header */}

        <div className="bg-customGray mt-2 flex w-full px-2 py-3  justify-between">
          {headeritems.map((item) => (
            <p className="uppercase text-xs mr-10 text-black">{item}</p>
          ))}
        </div>

        <div className="w-full  overflow-scroll ov ">
          {selectedfees.map((item, key) => (
            <FeesItem
              showeditmodal={setshowaddfeemodal}
              showdelete={setshowdeletefeemodal}
              setselected={setselectedfee_}
              key={key}
              item={item}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export { Admin_Fees };
