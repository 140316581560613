import { useState } from "react";
import Spinner from "../generic/Spinner";
import MapComponent from "../ui/MapItem";
import { useDispatch, useSelector } from "react-redux";
import { useLocationHook } from "../../Hooks/useLocationHook";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const MapAdmin =()=>{
    const [loading, setLoading] = useState(false);
var dispatcher =useDispatch()
const school_=useSelector((state)=>state.selectedSchool)

var locationhook=useLocationHook()
var navigate=useNavigate()
    dispatcher({ type: "set-page-title", payload: "School map location" });


    return(<>
     <div className="bg-customGray h-full px-10 ">

     <Spinner loading={loading} />
     <div className="flex   items-center">

<FaArrowLeft className="m-3 mb-3 text-primary" size={30} onClick={()=>navigate(-1)}/><h1>{school_.schoolname} Precise map location</h1>
</div> 

     <MapComponent 
       userschool={school_}
     setLoading={setLoading} Hook={locationhook} isViewonly={false}/>

     </div>
    </>)
}

export{MapAdmin}