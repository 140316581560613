import { FaCheck, FaCheckCircle, FaMailBulk } from "react-icons/fa";
import { logo, logolight } from "../../constants/imports";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Spinner from "../../Components/generic/Spinner";
import { Sendchangepasswordlink } from "../../Utils/govfunctions";
import InputField from "../../Components/generic/Inputfield";
import { emailReg } from "../../Utils/regex";
import Button from "../../Components/generic/Button";

const Requestchange = () => {
  var navigate = useNavigate();
  var [loading, SetLoading] = useState();
  var { control, handleSubmit } = useForm();

  const onrequest = async (data) => {
    SetLoading(true);
    var res = await Sendchangepasswordlink(data.email);
    //console.log(res.=)
    SetLoading(false);
    toast.success("Email to reset password sent!");
  };

  return (
    <div className="bg-primary flex justify-center items-center h-[100vh] w-full ">
      <Spinner loading={loading} />

      <div className="bg-white rounded-lg  w-[80vw]  py-6     flex justify-center flex-col  items-center">
        <img
          className="flex justify-center mb-4  w-[130px]  items-center"
          src={logo}
        />
        <FaMailBulk className="text-primary mb-4" size={30} />

        <h1 className="text-lg font-light mb-4">
          {" "}
          <p>
            Request to reset your password, we will send an email link to change
            your password
          </p>
        </h1>

        <InputField
          name="email"
          title="Email Address"
          placeholder="Enter Email Address"
          control={control}
          rules={{
            required: "Email Address is required",
            pattern: {
              value: emailReg,
              message: "Invalid Email Address",
            },
          }}
        />
        <Button
          width={"auto"}
          text="Send"
          onBtnClick={handleSubmit(onrequest)}
        />
      </div>
    </div>
  );
};

export { Requestchange };
