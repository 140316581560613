import axios from "axios";
import { baseURL } from "../URL";

const onLogin = async (postData) => {
  try {
    const response = await axios.post(`${baseURL}/logingovstaff`, postData);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};




const onChangeuserPass = async (postData) => {
  try {
      const response = await axios.post(`${baseURL}/govstaff/changepassword`, postData);
      return response.data;
  } catch (error) {
      if (error?.response?.data?.message === undefined) {
          throw error.message
      } else {
          throw error?.response?.data?.message;
      }
  }
}
export { onLogin , onChangeuserPass};
