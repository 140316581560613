import { useForm } from "react-hook-form";
import InputField from "../generic/Inputfield"
import { numReg } from "../../Utils/regex";
import Button from "../generic/Button";
import { onDeleteFee, onEditFee } from "../../services/fees";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { onDeleteFacility } from "../../services/facility";

const ConfirmDelete=(props)=>{
  
 

const submit=async(data)=>{
 
 props.loading(true)

 await Promise.all(props.titlelist.map(async (item) => {
try{


    const postdata={

        school:props.school,
        title:item,
     }
    
    var res =await onDeleteFacility(postdata)
    if(res.message){
        toast.success(res.message)
     
      
       // props.showaddmodal(false)

    }

    props.loading(false)


}
    
catch(e){

console.log(e)
props.loading(false)


}
 }


 ))
 props.showaddmodal(false)

// window.location.reload();


}
    return(<div className=" fixed w-[100vw] h-[100vh] top-0 left-0 flex bg-[#00000073] justify-center items-center ">

<div className='bg-white rounded w-[80vw] md:w-[30vw] p-10 '>    


<h2 className='text-primary'>Are you sure you want to delete this item(s)?</h2>
 

  


<div className='w-full  flex  gap-3 justify-end'> 

     
<Button  width={40} outlined text="Cancel" onBtnClick={()=>{   props.showaddmodal(false)}} />
<Button width={55}  text="Delete" onBtnClick={submit} />

</div>
</div>




        
    </div>)
}
export{ConfirmDelete}