import { useSelector } from "react-redux";
import MapComponent from "../../Components/school/MapItem";
import { useState } from "react";
import { useLocationHook } from "../../Hooks/useLocationHook";

const SchoolDeails = () => {
  var locationhook=useLocationHook()


const [loading, setLoading]=useState()
    const userschool=useSelector((state)=>state.selectedSchool)
  return (
    <div className="w-[70%]">
      <MapComponent
        userschool={userschool}
        setLoading={setLoading}
        Hook={locationhook}
        isViewonly={true}
      />

    </div>
  );
};
export { SchoolDeails };
