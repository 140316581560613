import { useForm } from "react-hook-form";
 import Button from "../generic/Button";
 import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { onDeleteCert } from "../../Utils/Servicess/certificate";

const ConfirmDelete=(props)=>{
    const { control, handleSubmit } = useForm();
    var authuser = useSelector((state) => state.userStore);

 

const submit=async(data)=>{

const postdata={

    email:authuser.email,
  
    name:props.selectedcert
}


 
 
 props.loading(true)
try{
    var res =await onDeleteCert(postdata)
    if(res.message){
        toast.success(res.message)
        props.refresh()
      
        props.showaddmodal(false)
        props.setcat(props.category)

    }

    props.loading(false)


}
    
catch(e){

console.log(e)
props.loading(false)


}





}
    return(<div className=" fixed w-[100vw] z-20  h-[100vh] top-0 left-0 flex bg-[#00000091] justify-center items-center ">

<div className='bg-white rounded min-w-[30vw] p-10 '>    


<h2 className='text-primary'>Are you sure you want to delete this item?</h2>
 

  


<div className='w-full  flex  gap-3 justify-end'> 

     
<Button  width={40} outlined text="Cancel" onBtnClick={()=>{   props.showaddmodal(false)}} />
<Button width={55}  text="Delete" onBtnClick={handleSubmit(submit)} />

</div>
</div>




        
    </div>)
}
export{ConfirmDelete}