import { useForm } from "react-hook-form";
import { LGAs, States } from "../../constants/data";
import { addressRegex, textReg } from "../../Utils/regex";
import Button from "../generic/Button";
import { ListPicker } from "../ui/ListPicker";
import InputField from "../generic/Inputfield";
import { toast } from "react-toastify";
import { usePickerhook } from "../../Hooks/usePickerhook";

const LocationModal = (props) => {
  const statepickerhook = usePickerhook();
  const lgapickerhook = usePickerhook();
  const { control, handleSubmit } = useForm();

  const onSubmit = (data) => {
    // if(!lgapickerhook.pickedRole){

    //   toast.error('Select an LGA')
    //   return
    // }

    const completeaddress = `${
      lgapickerhook.pickedRole ? lgapickerhook.pickedRole : ""
    } ${statepickerhook.pickedRole ? statepickerhook.pickedRole : ""} State`;
    console.log(completeaddress);
    props.setAddress(completeaddress);
    props.cancel(false);
  };
  return (
    <div className="fixed top-0 left-0 z-50 flex justify-center items-center bg-[#000000a7] w-[100vw] h-[100vh]">
      <div className="bg-white min-w-[30vw] rounded px-10 py-4">
        {/* <MapComponent
          isviewonly={false}
          cancel={props.cancel}
          Hook={props.Hook}
        /> */}
        <ListPicker
          title="Select state"
          default={"Osun"}
          list={States}
          hook={statepickerhook}
        />
        <ListPicker title="Select LGA" list={LGAs} hook={lgapickerhook} />

        <div className="w-full  flex  gap-3 justify-end">
          <Button
            width={40}
            outlined
            text="Cancel"
            onBtnClick={() => {
              props.cancel(false);
            }}
          />
          <Button width={55} text="Save" onBtnClick={handleSubmit(onSubmit)} />
        </div>
      </div>
    </div>
  );
};

export { LocationModal };
